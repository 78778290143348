import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateHelperService } from '@rp/services/date-helper';
import { Gap, HistoryRecord } from '@rp/models';
import * as fromApplicationForm from '../reducers/application-form.reducer';

/* eslint-disable @typescript-eslint/explicit-function-return-type */

const compareDates = (previousDate, currentDate): boolean => {
  const fromDate = DateHelperService.toDayjs(currentDate);
  const toDate = DateHelperService.toDayjs(previousDate);
  return DateHelperService.duration(fromDate.diff(toDate)).asMonths() > 1.5;
};

export const getApplicationFormState =
  createFeatureSelector<fromApplicationForm.State>('applicationForm');

export const selectStep = createSelector(
  getApplicationFormState,
  (state: fromApplicationForm.State) => state.step,
);

export const selectFormTime = createSelector(
  getApplicationFormState,
  (state: fromApplicationForm.State) => state.formStartTime,
);

export const selectFormTimeElapsed = createSelector(
  getApplicationFormState,
  (state: fromApplicationForm.State) => state.formStartTime + 1000 * 120,
);

export const selectSections = createSelector(
  getApplicationFormState,
  (state: fromApplicationForm.State) => state.sections,
);

export const selectCurrentSectionState = createSelector(
  selectSections,
  selectStep,
  (sections, step) => {
    if (sections) {
      return sections[step];
    }
    return undefined;
  },
);

export const selectEmploymentHistoryRecords = createSelector(
  selectSections,
  (sections) => {
    if (
      !!sections &&
      !!sections['employment-history'] &&
      !!sections['employment-history'].history
    ) {
      return [...sections['employment-history'].history].sort(
        (h1: HistoryRecord, h2: HistoryRecord) =>
          h2.data.dateFrom.getTime() - h1.data.dateFrom.getTime(),
      );
    }
    return [];
  },
);

export const selectEmploymentHistoryRecordsWithGaps = createSelector(
  selectEmploymentHistoryRecords,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  (history: any[]) =>
    history.reduce((acc: (HistoryRecord | Gap)[], curr: HistoryRecord) => {
      if (acc.length) {
        const previous = acc[acc.length - 1] as HistoryRecord;
        if (compareDates(curr.data.dateTo, previous.data.dateFrom)) {
          acc.push({
            type: 'gap',
            data: {
              dateFrom: curr.data.dateTo,
              dateTo: previous.data.dateFrom,
            },
          });
        }
      }
      acc.push(curr);
      return acc;
    }, []),
);

export const selectCurrentSectionSubsectionState = (id: string) =>
  createSelector(selectCurrentSectionState, (section) =>
    section ? section[id] : undefined,
  );

export const selectCurrentSectionSubsectionVisibility = (id: string) =>
  createSelector(selectCurrentSectionSubsectionState(id), (subsection) =>
    !!subsection && typeof subsection.visible !== 'undefined'
      ? subsection.visible
      : true,
  );

export const selectSectionsValidity = createSelector(
  getApplicationFormState,
  (state: fromApplicationForm.State) => state.sectionsValidity,
);

export const isSectionValid = (id: string) =>
  createSelector(
    selectSectionsValidity,
    (sectionsValidity) => sectionsValidity[id],
  );

export const introMessageClosed = createSelector(
  getApplicationFormState,
  (state: fromApplicationForm.State) => state.introMessageClosed,
);
